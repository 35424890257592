import { useController, useFormContext } from 'react-hook-form';

import { type DtoMessageLogicV2 } from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { apiService } from '../../../services/api-service';
import { HeaderLayout } from '../../Header';
import { LPLogo } from '../../icons/LPLogo';
import { type MessageCampaignV2 } from '../Shared';
import { type MessageLogicFormData } from './Shared';

export function MessageLogicEditorHeader(props: {
  onCancel: () => void;
  onSubmit: () => void;
}): JSX.Element {
  const { formState } = useFormContext();

  return (
    <HeaderLayout
      bgClassName={'bg-admin-red'}
      fill
      left={
        <div className='flex items-center gap-4'>
          <LPLogo type='admin' />
        </div>
      }
      right={
        <>
          <button
            className='btn-secondary w-34 h-10 flex flex-row justify-center items-center'
            type='button'
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <div className='relative'>
            <button
              className='btn-primary w-34 h-10 flex flex-row justify-center items-center'
              type='button'
              disabled={formState.isSubmitting}
              onClick={props.onSubmit}
            >
              {formState.isSubmitting ? 'Saving' : 'Save'}
            </button>
          </div>
        </>
      }
    />
  );
}

function NameField() {
  const {
    field: { value, onChange },
    fieldState,
  } = useController<MessageLogicFormData, 'name'>({
    name: 'name',
    rules: { required: true, maxLength: 100 },
  });

  return (
    <div className='w-full flex flex-col gap-2'>
      <label className='font-bold'>Name (Required)</label>
      <input
        className={`${fieldState.error ? 'field-error' : 'field'}  w-1/2 mb-0`}
        type='text'
        value={value}
        onChange={onChange}
        placeholder='Must be 1 to 100 characters'
      />
    </div>
  );
}

export function MessageLogicEditorLayout(props: {
  campaign: MessageCampaignV2;
  logic?: DtoMessageLogicV2;
  onCancel: () => void;
  onSubmit: (logic: DtoMessageLogicV2) => void;

  trigger?: React.ReactNode;
  target?: React.ReactNode;
  condition?: React.ReactNode;
  template?: React.ReactNode;
}) {
  const {
    campaign,
    logic,
    onCancel,
    onSubmit,
    trigger,
    target,
    condition,
    template,
  } = props;

  const { handleSubmit } = useFormContext<MessageLogicFormData>();

  const { call: submit } = useLiveAsyncCall(
    async (data: MessageLogicFormData) => {
      if (!logic) {
        const resp = await apiService.message.createLogic({
          name: data.name,
          campaignType: campaign.type,
          campaignId: campaign.id,
          target: data.target,
          trigger: data.trigger,
          condition: data.condition,
          template: data.template,
          tags: data.tags,
        });
        onSubmit(resp.data.logic);
        return;
      }

      const resp = await apiService.message.updateLogic(logic.id, {
        name: data.name,
        target: data.target,
        trigger: data.trigger,
        condition: data.condition,
        template: data.template,
        tags: data.tags,
      });
      onSubmit(resp.data.logic);
    }
  );

  return (
    <div className='w-full h-full flex flex-col'>
      <MessageLogicEditorHeader
        onCancel={onCancel}
        onSubmit={handleSubmit((data) => submit(data))}
      />

      <main className='w-full flex-1 overflow-auto p-10 text-white flex flex-col gap-10'>
        <div className='w-full flex flex-col gap-5'>
          <NameField />

          {trigger}
          {target}
          {condition}
        </div>

        {template}
      </main>
    </div>
  );
}
